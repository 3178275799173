import { ChakraProvider } from '@chakra-ui/react'
import ErrorBoundary from 'components/ErrorHandler'
import * as gtag from 'lib/gtag'
import { appWithTranslation } from 'next-i18next'
import Head from 'next/head'
import { useRouter } from 'next/router'
import { useEffect } from 'react'
import { customTheme } from 'styles/theme'
// Supports weights 300-700
import '@fontsource-variable/space-grotesk'

function MyApp({ Component, pageProps }) {
  const router = useRouter()

  useEffect(() => {
    const handleRouteChange = (url) => {
      gtag.pageview(url)
    }
    router.events.on('routeChangeComplete', handleRouteChange)
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange)
    }
  }, [router.events])

  return (
    <ChakraProvider resetCSS theme={customTheme}>
      <ErrorBoundary>
        <Head>
          <meta charSet='utf-8' />
          <meta httpEquiv='X-UA-Compatible' content='IE=edge' />
          <meta httpEquiv='ScreenOrientation' content='autoRotate:disabled' />
          <meta
            name='viewport'
            content='width=device-width,initial-scale=1,minimum-scale=1,maximum-scale=1,user-scalable=no'
          />
          <meta name='description' content='Autoinspector' />
          <meta
            name='keywords'
            content='autoinspector,seguros,revelacion,datascience,machinelearning'
          />

          <link rel='icon' href='/favicon.png' />
          <meta name='apple-mobile-web-app-title' content='Autoinspector' />
        </Head>
        <Component {...pageProps} />
      </ErrorBoundary>
    </ChakraProvider>
  )
}

export default appWithTranslation(MyApp)
